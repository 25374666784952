.Project {

    margin: 0 1rem 0;

}


.Project__image {

    width: 100%;

    max-width: 500px;

    border: 2px solid var(--accent-1);

    margin: 1rem 0 auto;

}


.Project__title {

    padding: .75rem;

    font-size: 1.25rem;

    margin: 0;

    background-color: var(--accent-1)
}


.Project__author {

    background: var(--accent-5);

    padding: 1rem;

}


.Project__title a {

    color: var(--base)
}


.Project__links {

    list-style: none;

    display: flex;

    max-width: 500px;

    font-size: 1.2em;

    padding: 0;

}


.Project__links li {

    background-color: var(--accent-1);

    color: var(--base);

    padding: 1rem;

    border: 1px solid var(--accent-4);

    margin-right: 1rem;

}


.Project__links:last-child {

    margin-right: 0;

}