.About__picture {

    background: url('../../assets/images/woods.png');

    background-repeat: repeat;

    background-position: top;

    background-attachment: fixed;

    background-color: var(--accent-4);

    padding: 1rem;

    max-width: 300px;

    border-radius: 100%;

    border: 3px solid var(--accent-5);

}


.About__p {

    max-width: 500px;

}


@media only screen and (min-width: 630px) {

    #About {

        grid-template-rows: auto auto auto;

        grid-template-columns: 1fr 1fr;

        grid-template-areas:

            'heading heading'

            'picture .'

            'picture .'

            'picture .';

        justify-content: space-between;

    }


    .About__picture {

        grid-area: picture;

        margin: 0 auto 1rem;

    }

}