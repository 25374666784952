.Skills__list {

    margin: 0 1rem 1rem 1.5rem;

    padding: 1rem 0 1rem .5rem;

    list-style: square;

    margin-block-start: 0;

    margin-block-end: 0;

}


.Skills__skillset {

    width: 80%;

    margin: 0 auto;

}


.Skills__title {

    background-color: var(--accent-1);

    color: var(--base);

    padding: .5rem;

    margin-bottom: .5rem;

    margin-block-start: 0;

    margin-block-end: 0;

}